<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon large v-on="on" @click.prevent="$router.push('/admin')">
          <v-icon size="30"> mdi-arrow-left </v-icon>
        </v-btn>
      </template>
      <span>{{ $i18n.t("TABLE.back") }}</span>
    </v-tooltip>
    <Table
      :data="users"
      :headers="headers"
      :loading="loading"
      :icon="'mdi-account'"
      :accept-companies="true"
      :companies="companies"
      @addBatch="addBatch"
      @save="showSave"
      @add="showAdd"
      @delete="showDelete"
      @getCompany="getUsers"
    />

    <v-dialog
      v-model="dialogs.add"
      persistent
      width="650px"
      overlay-opacity="0.5"
      :overlay-color="!$vuetify.theme.dark ? 'white' : '#303030'"
    >
      <v-card class="save-card px-8 pb-4 pt-8">
        <v-card-title v-if="dialogs.save">
          <v-spacer />
          <v-btn
            v-if="
              user.type != 'superuser' ||
              this.$store.state.Auth.token.role === 'superuser'
            "
            depressed
            :loading="buttonLoader"
            color="red"
            dark
            @click.prevent="dialogs.delete = true"
          >
            {{ $i18n.t("GENERAL.delete") }}
          </v-btn>
        </v-card-title>
        <v-card-text class="">
          <v-row no-gutters class="px-2 pt-5">
            <v-col cols="12" class="px-2">
              <v-form ref="add-form" lazy-validation>
                <v-text-field
                  filled
                  dense
                  rounded
                  v-model.trim="user.id"
                  :rules="[rules.required, rules.idPattern]"
                  prepend-icon="mdi-identifier"
                  :suffix="user.id + '@' + companySelected"
                  :label="$i18n.t('COMPANY.idLabel')"
                  :disabled="dialogs.save === true && !dialogs.add === false"
                  autocomplete="off"
                />
                <v-row no-gutters>
                  <v-col cols="6" class="pr-2">
                    <v-text-field
                      filled
                      dense
                      rounded
                      v-model.trim="user.fullname"
                      :rules="[rules.required]"
                      prepend-icon="mdi-account"
                      :label="$i18n.t('USER.nameLabel')"
                      autocomplete="do-not-autocomplete"
                    />
                  </v-col>
                  <v-col cols="6" class="pl-2">
                    <v-text-field
                      filled
                      dense
                      rounded
                      :rules="
                        this.dialogs.add && this.dialogs.save
                          ? []
                          : [rules.required]
                      "
                      type="password"
                      v-model.trim="user.password"
                      prepend-icon="mdi-lock"
                      :label="$i18n.t('USER.passwordLabel')"
                      :disabled="
                        user.type === 'superuser' &&
                        this.$store.state.Auth.token.role === 'admin'
                      "
                      autocomplete="do-not-autocomplete"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" class="pl-2">
                    <v-select
                      v-model="user.type"
                      filled
                      :rules="[rules.required]"
                      rounded
                      :items="userTypes"
                      :label="$i18n.t('USER.typeLabel')"
                      prepend-icon="mdi-account-box"
                      :disabled="
                        user.type === 'superuser' &&
                        this.$store.state.Auth.token.role === 'admin'
                      "
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" class="pl-2">
                    <v-text-field
                      filled
                      dense
                      rounded
                      v-model="user.externalid"
                      prepend-icon="mdi-account"
                      :label="$i18n.t('USER.externalIdLabel')"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="6" class="pr-2">
                    <v-text-field
                      filled
                      dense
                      rounded
                      v-model.trim="user.email"
                      prepend-icon="mdi-email"
                      :label="$i18n.t('USER.mailLabel')"
                    />
                  </v-col>
                  <v-col cols="6" class="pl-2">
                    <vue-tel-input
                      ref="telephoneNumber"
                      :placeholder="$i18n.t('USER.phoneLabel')"
                      @input="onInputPhone"
                      v-model="user.phone"
                    />
                    <label style="color: red" v-show="errorPhone">{{
                      $i18n.t("USER.INVALIDPHONE")
                    }}</label>
                  </v-col>
                </v-row>

                <v-autocomplete
                  :items="langs"
                  filled
                  dense
                  rounded
                  v-model="user.lang"
                  prepend-icon="mdi-web"
                  :label="$i18n.t('SETTING.lenguage')"
                />
                <v-textarea
                  filled
                  rows="4"
                  dense
                  rounded
                  v-model="user.description"
                  prepend-icon="mdi-card-text"
                  :label="$i18n.t('USER.descriptionLabel')"
                />
                <v-switch
                  style="position: absolute; z-index: 2"
                  v-model="user.disabled"
                  :label="$i18n.t('ADMIN.disabled')"
                />
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12" style="text-align: end">
              <v-btn text class="mr-1" @click="closeAdd">
                {{ $i18n.t("GENERAL.close") }}
              </v-btn>
              <v-btn
                v-if="dialogs.add && !dialogs.save"
                depressed
                :loading="buttonLoader"
                color="green"
                dark
                @click.prevent="add"
              >
                {{ $i18n.t("GENERAL.add") }}
              </v-btn>
              <template v-else>
                <v-btn
                  depressed
                  :loading="buttonLoader"
                  color="green"
                  dark
                  @click.prevent="save"
                >
                  {{ $i18n.t("GENERAL.save") }}
                </v-btn>
              </template>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Delete
      v-model="dialogs.delete"
      @remove="remove"
      @close="closeDelete"
      :loading="buttonLoader"
    />
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import User from "@/models/user.js";
import Company from "@/models/company.js";
import Delete from "@/components/Delete.vue";

export default {
  components: {
    Table,
    Delete,
  },
  data() {
    return {
      langs: [
        {
          text: "English (EE.UU.)",
          value: "en-US",
        },
        {
          text: "English (Australia)",
          value: "en-AU",
        },
        {
          text: "English (India)",
          value: "en-IN",
        },
        {
          text: "English (United Kingdom)",
          value: "en-GB",
        },
        {
          text: "Español (España)",
          value: "es-ES",
        },
        {
          text: "Español (Argentina)",
          value: "es-AR",
        },
        {
          text: "Español (Bolivia)",
          value: "es-BO",
        },
        {
          text: "Español (Chile)",
          value: "es-CL",
        },
        {
          text: "Español (Colombia)",
          value: "es-CO",
        },
        {
          text: "Español (Costa Rica)",
          value: "es-CR",
        },
        {
          text: "Español (Ecuador)",
          value: "es-EC",
        },
        {
          text: "Español (Uruguay)",
          value: "es-UY",
        },
        {
          text: "Español (Guatemala)",
          value: "es-GT",
        },
        {
          text: "Español (Honduras)",
          value: "es-HN",
        },
        {
          text: "Español (México)",
          value: "es-MX",
        },
        {
          text: "Español (Nicaragua)",
          value: "es-NI",
        },
        {
          text: "Español (Panamá)",
          value: "es-PA",
        },
        {
          text: "Español (Paraguay)",
          value: "es-PY",
        },
        {
          text: "Español (Perú)",
          value: "es-PE",
        },
        {
          text: "Español (Puerto Rico)",
          value: "es-PR",
        },
        {
          text: "Español (Republica Dominicana)",
          value: "es-DO",
        },
        {
          text: "Español (Venezuela)",
          value: "es-VE",
        },
      ],
      loading: true,
      userTypes: [],
      companySelected: "",
      isCompanySelectedInternal: false,
      user: {},
      users: [],
      companies: [],
      buttonLoader: false,
      rules: {
        required: (value) => !!value || "required",
        idPattern: (value) =>
          !/.*[\s@].*/.test(value) || "id must not contain spaces or @",
      },
      dialogs: {
        delete: false,
        add: false,
        save: false,
      },
      errorPhone: false,
    };
  },
  created() {
    this.getCompanies();
  },
  methods: {
    validatePhoneInput() {
      if (!this.user.phone) {
        return true;
      }
      const isValid = this.$refs.telephoneNumber.phoneObject.isValid;
      isValid ? (this.errorPhone = false) : (this.errorPhone = true);
      return isValid;
    },

    onInputPhone() {
      this.validatePhoneInput();
    },

    async getUsers(companyId) {
      try {
        this.loading = true;
        this.companySelected = companyId;
        this.users = await User.getAll(companyId);

        this.companies.forEach((c) => {
          if (c.id === this.companySelected) {
            this.isCompanySelectedInternal = c.internal;
          }
        });
        if (
          this.$store.state.company.internal &&
          this.isCompanySelectedInternal &&
          this.$store.state.Auth.token.role === "superuser"
        ) {
          this.userTypes = ["agent", "admin", "superuser"];
        } else {
          this.userTypes = ["agent", "admin"];
        }

        this.loading = false;
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "GET",
          text: "Error fetching users",
        });
      }
    },

    async getCompanies() {
      try {
        this.companies = await Company.getAll();
        if (this.$store.state.Auth.token.role === "admin") {
          this.companySelected = this.companies[0].id;
          await this.getUsers(this.companySelected);
        }
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "GET",
          text: error,
        });
      }
    },

    async remove() {
      this.buttonLoader = true;
      try {
        const requestUser = this.$store.state.Auth.token.claims.user_id;
        await User.remove(this.user, this.companySelected, requestUser);
        this.getUsers(this.companySelected);
        this.closeDelete();
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "success",
          title: "DELETE",
          text: "User removed successfully",
        });
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "DELETE",
          text: "Error in remove user",
        });
      }
      this.buttonLoader = false;
    },

    async add() {
      this.buttonLoader = true;

      if (this.$refs["add-form"].validate() && this.validatePhoneInput()) {
        this.user.phone = this.$refs.telephoneNumber.phoneObject.number.e164;
        try {
          const requestUser = this.$store.state.Auth.token.claims.user_id;
          const user = new User(this.user);
          await User.add(user, this.companySelected, requestUser);
          this.getUsers(this.companySelected);
          this.closeAdd();
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "success",
            title: "",
            text: this.$i18n.t("ADMIN.ADDSUCCESS"),
          });
        } catch (error) {
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "error",
            title: "",
            text: error,
          });
        }
      }
      this.buttonLoader = false;
    },
    async addBatch(batch) {
      const requestUser = this.$store.state.Auth.token.claims.user_id;
      const promises = batch.map((data) => {
        const user = new User(data);
        return new Promise((resolve, reject) => {
          User.add(user, this.companySelected, requestUser)
            .then(resolve)
            .catch(reject);
        });
      });
      Promise.allSettled(promises).then((results) => {
        const errors = results.filter((result) => result.status === "rejected");
        if (errors.length === 0) {
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "success",
            title: "",
            text: "All users created successfully",
          });
        } else {
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "error",
            title: "",
            text: "There was an error creating " + errors.length + " users",
          });
        }
        this.getUsers(this.companySelected);
      });
    },
    async save() {
      this.buttonLoader = true;
      const requestUser = this.$store.state.Auth.token.claims.user_id;
      if (this.$refs["add-form"].validate()) {
        try {
          const user = new User(this.user);
          await User.update(user, this.companySelected, requestUser);
          this.getUsers(this.companySelected);
          this.closeAdd();
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "success",
            title: "SAVE",
            text: "User updated successfully",
          });
        } catch (error) {
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "error",
            title: "SAVE",
            text: "Error in save user: " + error.message,
          });
        }
      }
      this.buttonLoader = false;
    },

    closeDelete() {
      this.dialogs.delete = !this.dialogs.delete;
    },

    closeAdd() {
      this.dialogs.add = false;
      this.dialogs.save = false;
      this.$refs["add-form"].reset();
    },
    // This function is used to show the delete modal.
    // It takes in a user object and sets it to the component's user property
    // It also toggles the dialogs.delete property
    // The dialogs.delete property is used to show the modal
    showDelete(user) {
      this.dialogs.delete = !this.dialogs.delete;
      this.user = user;
    },
    // showAdd() resets the add-form and sets the user to a new User object, then toggles the add dialog.
    showAdd() {
      if (this.$refs["add-form"]) {
        this.$refs["add-form"].reset();
      }
      this.user = new User({});
      this.dialogs.add = !this.dialogs.add;
    },
    showSave(user) {
      this.user = new User(user);
      this.dialogs.add = true;
      this.dialogs.save = true;
    },
  },
  computed: {
    headers() {
      return [
        { text: this.$i18n.t("USER.tableHeaders.id"), value: "id" },
        { text: this.$i18n.t("USER.tableHeaders.name"), value: "fullname" },
        {
          text: this.$i18n.t("USER.tableHeaders.description"),
          value: "description",
        },
        { text: this.$i18n.t("USER.tableHeaders.type"), value: "type" },
      ];
    },
  },
};
</script>

<style lang="scss">
.save-card {
  border-top: 3px solid var(--v-primary-base) !important;
}

.vti__dropdown-list {
  left: -81px !important;
  padding: 0px !important;
}

.vue-tel-input {
  border-radius: 64px !important;
  height: 55px !important;
  display: flex !important;
  border: 0px solid #bbb !important;
  text-align: left !important;
  background: #f0f0f0 !important;
}

.vue-tel-input:focus {
  outline: 0px !important;
  box-shadow: 0px 0px !important;
}
.vti__dropdown:focus {
  outline: 0px !important;
  box-shadow: 0px 0px !important;
}
.vti__dropdown:hover {
  background-color: #f3f3f3 !important;
  border-radius: 54px !important;
}
.vti__dropdown.open {
  background-color: #f3f3f3 !important;
  border-radius: 54px !important;
}

.theme--dark .vue-tel-input {
  background-color: #272727 !important;
}

.theme--dark .vti__dropdown-list {
  background-color: #272727 !important;
}

.theme--dark .vti__dropdown-item.highlighted {
  background-color: #303030 !important;
}

.theme--dark .vti__dropdown.open {
  background-color: #303030 !important;
}

.theme--dark .vti__input {
  color: white;
}
</style>
